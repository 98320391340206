/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($, _) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#nav-expander').on('click',function(e){
          e.preventDefault();
          $('body').toggleClass('nav-expanded');
          $(this).toggleClass('is-active');
        });
        $('a.scroll, .scroll a').smoothScroll({
          speed: 900,
        });
        $('a.dropdown-toggle').on('click', function(e){
          if( $(this).parent().hasClass('open') ) {
            $(this).children()
                   .removeClass('ss-navigateup')
                   .addClass('ss-navigatedown');
          } else {
            $(this).children()
                   .removeClass('ss-navigatedown')
                   .addClass('ss-navigateup');
          }
        });

        $('.blog .search-submit, .category .search-submit').val('&#xf002');
        $('.blog select, .category select').selectric({
          arrowButtonMarkup: '<b class="ss-gizmo ss-navigatedown"></b>',
        });

        $('.slick-carousel').slick();

        $('.card-slider-carousel').slick({
          slidesToShow: 2,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              },
            },
          ]
        });

        $('.collapse').on('show.bs.collapse', function () {
          $(this).prev().find(".ss-icon").removeClass("ss-down").addClass("ss-up");
        });
        $('.collapse').on('hide.bs.collapse', function () {
          $(this).prev().find(".ss-icon").removeClass("ss-up").addClass("ss-down");
        });


        var squaredBlockButtons = $('.sidebar-right-square a.btn').length;
        if( squaredBlockButtons > 1 ) {
          $('.sidebar-right-square').addClass('multiple-buttons');
        }

        $('.menu-search a').click( function(e){
          return;
          e.stopPropagation();
          $('.menu-item-search').addClass('display-form');
          $(this).parent().hide();
          $('#s').focus();
        });

        $("#s").click( function(e){
          e.stopPropagation();
        });

        $('html').click(function() {
          $('.menu-item-search').removeClass('display-form');
          $('.menu-search').show();
        });

        $(window).on('load', function() {
          var imgHeight = $('.col-img-wrap img').height();
          $('.col-img-wrap').css('padding-top', imgHeight + 'px');
        });
        $(window).resize(function() {
          var imgHeight = $('.col-img-wrap img').height();
          $('.col-img-wrap').css('padding-top', imgHeight + 'px');
        });

        $(window).trigger('resize');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'groups_shortcuts': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.group-shortcuts-slider').slick({
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 3,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1
              }
            }
          ]
        });
      },
    },
    'policy_reports': {

      init: function() {

      function isScrolledIntoView(elem)
        {
          var docViewTop = $(window).scrollTop();
          var docViewBottom = docViewTop + $(window).height();

          var elemTop = $(elem).offset().top;
          var elemBottom = elemTop + $(elem).height()-1;

          return ((docViewTop < elemTop) && (docViewBottom >= elemBottom));
        }

      var baseURL = "/wp-json/wp/v2/";

      var bus = new Vue();

      Vue.filter('formatDate', function(value) {
        if (value) {
          return moment(String(value)).format('MM/DD/YYYY');
        }
      });

      Vue.component('publications-filters', {
        template: '#publications-filters',
        data: function() {
          return {
            currentType: null,
            currentEvent: null,
            searchTerms: '',
            allTypesLabel: 'All States',
            allEventsLabel: 'All Events',
            eventsOnlyForType: 'poster',
            enableEventFilter: true
          };
        },
        props: {
          events: {
            type: Array,
            default: []
          },
          types: {
            type: Array,
            default: []
          }
        },
        created: function () {
          if (location.hash) {
            var initialType = {};
            hashRaw = window.location.hash.substring(1).split('/');

            initialType.name = hashRaw[0];
            initialType.id = hashRaw[1];

            this.setTypeFilter(initialType);
          }

        },
        methods: {
          setTypeFilter: function(type) {
            this.currentType = type;
            bus.$emit('set-type-filter', (type !== null)? type.id: null);
          },
          doSearch: _.debounce(function() {
            bus.$emit('search', this.searchTerms);
          }, 500)
        },
        watch: {
          'searchTerms': function(newTerms, oldTerms) {
            this.doSearch();
          }
        }
      });

      Vue.component('publications-list', {
        template: '#publications-list',
        props: {
          publications: {
            type: Array,
            default: []
          },
          isFetchingPublications: {
            type: Boolean,
            default: false
          },
          isFetchingFilters: {
            type: Boolean,
            default: false
          },
          isLoadingMore: {
            type: Boolean,
            default: false
          },
          typesById: {
            type: Object,
            default: {}
          }
        },
        created: function() {
          this.initScrollLoading();
        },
        methods: {
          loadMore: function() {
            bus.$emit('load-more');
          },
          initScrollLoading: function() {
            window.addEventListener('scroll', this.scrollHandler);
          },
          scrollHandler: function() {
            var elm = this.$refs['scroll-loading'];
            if (isScrolledIntoView(elm)) {
              this.loadMore();
            }
          }
        }
      });

      var app = new Vue({
        el: "#app",
        data: {
          searchTerms: '',
          publicationTypeId: null,
          publicationsList: [],
          totalPages: '',
          types: [],
          typesById: {},
          isFetchingPublications: false,
          isLoadingMore: false,
          pageNum: 1,
          perPage: 20
        },
        created: function() {
          this.getTypes();
          this.updateList();

          var vm = this;

          bus.$on('search', function(terms) {
            vm.searchTerms = terms;
            vm.updateList();
          });
          bus.$on('set-type-filter', function(newTypeId) {
            vm.publicationTypeId = newTypeId;
            vm.updateList();
          });
          bus.$on('load-more', function() {
            vm.loadMore();
          });
        },
        methods: {
          getTypes: function() {
            this.$http.get(baseURL + 'state?per_page=52').then(function(res) {
              this.types = res.body;
              this.createDict(this.types, 'id', this.typesById);
              this.updateList();
            }, function(res) {
              console.log('error', res);
            });
          },
          createDict: function(arr, key, dict) {
            for (var i=0; i<arr.length; i++) {
              dict[arr[i][key]] = arr[i];
            }
          },
          updateList: function() {
            this.pageNum = 1;
            if (this.isFetchingPublications || this.isFetchingFilters) {
              return;
            }
            this.isFetchingPublications = true;
            this.$http.get(this.publicationsURL).then(function(res) {
              this.publicationsList = res.body;
              this.totalPages = res.headers.getAll('X-WP-TotalPages')[0];
              console.log(this.totalPages);
              this.isFetchingPublications = false;
            }, function(res) {
              console.log('error', res);
            });
          },
          loadMore: function() {
            console.log (this.pageNum + ' ' + this.totalPages);
            if (this.isLoadingMore || this.pageNum >= this.totalPages) {
              return;
            }
            this.isLoadingMore = true;
            this.pageNum++;
            console.log(this.pageNum);
            this.$http.get(this.publicationsURL + "&page=" + this.pageNum).then(function(res) {
              this.publicationsList = this.publicationsList.concat(res.body);
              this.isLoadingMore = false;
            }, function(res) {
              console.log('error', res);
            });
          }
        },
        computed: {
          isFetchingFilters: function() {
            return (0 === this.types.length);
          },
          publicationsURL: function() {
            var url = baseURL + 'report?per_page=' + this.perPage;
            if (this.publicationTypeId) {
              url += '&state=' + this.publicationTypeId;
            }
            if (this.searchTerms !== '') {
              url += '&search=' + encodeURI(this.searchTerms);
            }
            return url;
          }
        }
      });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }


    },
    'position_statements': {

      init: function() {

      function isScrolledIntoView(elem)
        {
          var docViewTop = $(window).scrollTop();
          var docViewBottom = docViewTop + $(window).height();

          var elemTop = $(elem).offset().top;
          var elemBottom = elemTop + $(elem).height()-1;

          return ((docViewTop < elemTop) && (docViewBottom >= elemBottom));
        }

      var baseURL = "/wp-json/wp/v2/";

      var bus = new Vue();

      Vue.filter('formatDate', function(value) {
        if (value) {
          return moment(String(value)).format('MM/DD/YYYY');
        }
      });

      Vue.component('publications-filters', {
        template: '#publications-filters',
        data: function() {
          return {
            currentType: null,
            currentEvent: null,
            searchTerms: '',
            allTypesLabel: 'All Types',
            allEventsLabel: 'All Events',
            eventsOnlyForType: 'poster',
            enableEventFilter: true
          };
        },
        props: {
          events: {
            type: Array,
            default: []
          },
          types: {
            type: Array,
            default: []
          }
        },
        created: function () {
          if (location.hash) {
            var initialType = {};
            hashRaw = window.location.hash.substring(1).split('/');

            initialType.name = hashRaw[0];
            initialType.id = hashRaw[1];

            this.setTypeFilter(initialType);
          }

        },
        methods: {
          setTypeFilter: function(type) {
            this.currentType = type;
            bus.$emit('set-type-filter', (type !== null)? type.id: null);
          },
          doSearch: _.debounce(function() {
            bus.$emit('search', this.searchTerms);
          }, 500)
        },
        watch: {
          'searchTerms': function(newTerms, oldTerms) {
            this.doSearch();
          }
        }
      });

      Vue.component('publications-list', {
        template: '#publications-list',
        props: {
          publications: {
            type: Array,
            default: []
          },
          isFetchingPublications: {
            type: Boolean,
            default: false
          },
          isFetchingFilters: {
            type: Boolean,
            default: false
          },
          isLoadingMore: {
            type: Boolean,
            default: false
          },
          typesById: {
            type: Object,
            default: {}
          }
        },
        created: function() {
          this.initScrollLoading();
        },
        methods: {
          loadMore: function() {
            bus.$emit('load-more');
          },
          initScrollLoading: function() {
            window.addEventListener('scroll', this.scrollHandler);
          },
          scrollHandler: function() {
            var elm = this.$refs['scroll-loading'];
            if (isScrolledIntoView(elm)) {
              this.loadMore();
            }
          }
        }
      });

      var app = new Vue({
        el: "#app",
        data: {
          searchTerms: '',
          publicationTypeId: null,
          publicationsList: [],
          totalPages: '',
          types: [],
          typesById: {},
          isFetchingPublications: false,
          isLoadingMore: false,
          pageNum: 1,
          perPage: 20
        },
        created: function() {
          this.getTypes();
          this.updateList();

          var vm = this;

          bus.$on('search', function(terms) {
            vm.searchTerms = terms;
            vm.updateList();
          });
          bus.$on('set-type-filter', function(newTypeId) {
            vm.publicationTypeId = newTypeId;
            vm.updateList();
          });
          bus.$on('load-more', function() {
            vm.loadMore();
          });
        },
        methods: {
          getTypes: function() {
            this.$http.get(baseURL + 'position_category?per_page=100').then(function(res) {
              this.types = res.body;
              this.createDict(this.types, 'id', this.typesById);
              this.updateList();
            }, function(res) {
              console.log('error', res);
            });
          },
          createDict: function(arr, key, dict) {
            for (var i=0; i<arr.length; i++) {
              dict[arr[i][key]] = arr[i];
            }
          },
          updateList: function() {
            this.pageNum = 1;
            if (this.isFetchingPublications || this.isFetchingFilters) {
              return;
            }
            this.isFetchingPublications = true;
            this.$http.get(this.publicationsURL).then(function(res) {
              this.publicationsList = res.body;
              this.totalPages = res.headers.getAll('X-WP-TotalPages')[0];
              this.isFetchingPublications = false;
            }, function(res) {
              console.log('error', res);
            });
          },
          loadMore: function() {
            console.log (this.pageNum + ' ' + this.totalPages);
            if (this.isLoadingMore || this.pageNum >= this.totalPages) {
              return;
            }
            this.isLoadingMore = true;
            this.pageNum++;
            console.log(this.pageNum);
            this.$http.get(this.publicationsURL + "&page=" + this.pageNum).then(function(res) {
              this.publicationsList = this.publicationsList.concat(res.body);
              this.isLoadingMore = false;
            }, function(res) {
              console.log('error', res);
            });
          }
        },
        computed: {
          isFetchingFilters: function() {
            return (0 === this.types.length);
          },
          publicationsURL: function() {
            var url = baseURL + 'statement?per_page=' + this.perPage;
            if (this.publicationTypeId) {
              url += '&position_category=' + this.publicationTypeId;
            }
            if (this.searchTerms !== '') {
              url += '&search=' + encodeURI(this.searchTerms);
            }
            return url;
          }
        }
      });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }


    },
    'single_post': {
      init: function() {
        (function checkImageOverflow(){
          var postWidth = $('main article').innerWidth();
          $('main article img').each(function() {
            var imageWidth = this.width;
            if (imageWidth > postWidth) {
              $(this).addClass('img-responsive');
            }
          });
        })();
      }
    },
    // About us page, note the change from about-us to about_us.
    'speakers': {
      init: function() {
        // JavaScript to be fired on the about us page
        $slick_slider = $('.slider');
        settings = {
          // some settings
          arrows: false,
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: false,
          centerPadding: '50px',
          responsive: [{
            breakpoint: 350,
            settings: {
              centerPadding: '40px'
            }
          }]
        };
        if ($(window).width() < 768) {
          $slick_slider.slick(settings);
        }

        // reslick only if it's not slick()
        $(window).on('resize', function() {
          if ($(window).width() > 768) {
            if ( $slick_slider.hasClass('slick-initialized')) {
              $slick_slider.slick('unslick');
            }
            return;
          }

          if (!$slick_slider.hasClass('slick-initialized')) {
            return $slick_slider.slick(settings);
          }
        });

        var singlePage = $('.cbp-js-singlePage').children('div');
        $('.grid-container').cubeportfolio({
          filters: '#filters-container',
          loadMore: '#loadMore-container',
          loadMoreAction: 'click',
          layoutMode: 'grid',
          mediaQueries: [{
            width: 1100,
            cols: 4
          }, {
            width: 800,
            cols: 4
          }, {
            width: 500,
            cols: 3
          }, {
            width: 320,
            cols: 1
          }],
          defaultFilter: '*',
          animationType: 'rotateSides',
          gapHorizontal: 10,
          gapVertical: 10,
          gridAdjustment: 'responsive',
          caption: 'fadeIn',
          displayType: 'sequentially',
          displayTypeSpeed: 100,

          // singlePageInline
          singlePageInlineDelegate: '.cbp-singlePageInline',
          singlePageInlinePosition: 'below',
          singlePageInlineInFocus: true,
          singlePageInlineDeeplinking: true,
          singlePageInlineCallback: function(url, element) {
            // to update singlePageInline content use the following method: this.updateSinglePageInline(yourContent)
            var t = this;

            var indexElement = $(element).parents('.cbp-item').index(),
                item = singlePage.eq(indexElement);

            this.updateSinglePageInline(item.html());

          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery, lodash); // Fully reference jQuery after this point.
